import { Axios } from "./network/interceptor";
import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  baseUrl,
  tokenVerifyUrl,
  zoneAdminUrl,
} from "../src/views/utils/api/api";
import UserContext from "./context/UserContext";
import "./scss/style.scss";
import mzConstant from "./views/utils/var";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/Auth/Login"));
const ChangePassword = React.lazy(() =>
  import("./views/ZoneAdmin/ChangePassword")
);
const TailorsappChangePassword = React.lazy(() =>
  import("./views/Tailors&Designers/ChangePassword")
);
const InVoice = React.lazy(() => import("./views/invoice/invoice.js"));

toast.configure();
export default function App() {
  const history = useHistory();

  
  const [userData, setUserData] = useState({
    token: null,
    user_type: null,
    user_id: null,
  });
  const providerValue = useMemo(()=>({userData, setUserData}),[userData, setUserData])
  
  const checkLoggedIn = async () => {
    const tokenVerify = await Axios.post(
      baseUrl + zoneAdminUrl + tokenVerifyUrl,
      null
    );
    if (tokenVerify.data.success) {
      const user_type = tokenVerify.data.user_type;
      if (user_type == mzConstant.gc_mz_zoneAdmin) {
         
        if (tokenVerify.data.is_active == mzConstant.inActive) {
          toast.warn(
            "Your Account is Deactivated Pls Contact our Support team"
          );
          localStorage.setItem("auth-token", "");
          localStorage.setItem("auth-user_type", "");
          setUserData({
            token: undefined,
            user_type: undefined,
            user_id: undefined,
          });
        } else {
          if (tokenVerify.data.changePassword == 1) {
            setUserData({
              user_type,
              token: tokenVerify.data.token,
              user_id: tokenVerify.data.user_id,
            });
            toast.warn("Please Change your Password, before Proceeding");
            // history.push("/changepassword");
          } else if (tokenVerify.data.changePassword == 2) {
            setUserData({
              user_type,
              token: tokenVerify.data.token,
              user_id: tokenVerify.data.user_id,
            });
          }
        }
      } else if (user_type == mzConstant.gc_mz_siteAdmin) {
        setUserData({
          user_type,
          token: tokenVerify.data.token,
          user_id: tokenVerify.data.user_id,
        });
      }
    } else {
      toast.warn(tokenVerify.data.message);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <BrowserRouter>
      <UserContext.Provider value={providerValue}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/change-admin-password"
              name="Change Password"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              exact
              path="/tailorsapp-forgot-password/:token/:userType"
              name="Change Password"
              render={(props) => <TailorsappChangePassword {...props} />}
            />
            <Route
              exact
              path="/invoice-order/:order_no/:serviceId"
              name="Invoice Generate"
              render={(props) => <InVoice {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </UserContext.Provider>
    </BrowserRouter>
  );
}
